.player {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    background: var(--player-background);
    border-radius: 20px 20px 0 0;
    min-width: 320px;
    max-width: 740px;
    width: 100%;
    box-shadow: 0px 0px 64px rgba(1, 24, 44, 0.15);
    z-index: 300;
    left: calc(50% - 370px);
    padding: 24px 24px 24px 24px;
}

.player-full {
    padding: 24px 24px 42px;
}

.player .silent-text {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    font-family: "Roboto", -apple-system, "Open Sans", system-ui, "sans-serif";
    font-weight: 500;
    width: 100%;
    color: #999999;
}

.player .silent-text div {
    display: inline-block;
}

.player .links .link.disabled svg {
    fill: #dbdbdb;
    cursor: default;
}

.img-btn,
.img-btn-full {
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    padding: 0 20px 0 0;
}

.play-episode:hover span {
    color: #fc7e0f;
}

@media (pointer: fine) {
    .play-episode:hover span {
        color: #fc7e0f;
    }
}

.action-bar {
    display: flex;
    align-items: center;
    min-height: 32px;
}

.action {
    display: flex;
    flex-wrap: nowrap;
}

.action:first-child {
    margin: 0 32px;
}

.action .action-btn:first-child {
    margin-right: 16px;
}

.action .action-btn {
    outline: none;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
}

.action .action-btn img {
    height: 24px;
}

/* TODO need svg sprite */
.action-btn:active svg,
.action-btn:active:focus svg {
}

.player .podcast-time {
    color: #999999;
    font-family: "Roboto", -apple-system, "Open Sans", system-ui, "sans-serif";
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 4px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.player .podcast-time strong {
    font-weight: 500;
}


.progress-container {
    order: 2;
}

.progress-container .timeline {
    position: relative;
    border-radius: 2px;
    height: 2px;
    background: rgba(30, 139, 228, 0.3);
}

.player .loading,
.player .podcast-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 2px;
    width: 100%;
}

.player .loading {
    background: rgba(32, 70, 101, 0.15) url(loading.png) repeat-x 0 0;
    background-size: 7px 100%;
    -webkit-animation: loading linear 0.25s infinite;
    animation: loading linear 0.25s infinite;
}

@-webkit-keyframes loading {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 7px 0;
    }
}

@keyframes loading {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 7px 0;
    }
}

.player .podcast-progress {
    background: #1e8be4;
}

.podcast-progress .handle-progress-wrapper {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -15px;
    right: -15px;
}

.podcast-progress .handle-progress {
    cursor: pointer;
    width: 13px;
    height: 13px;
    border-style: solid;
    border-width: 4px;
    border-color: #1e8be4;
    background: #1e8be4;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 50%;
    margin: -6px;
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.podcast-progress .handle-progress:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
}

@media (pointer: fine) {
    .podcast-progress .handle-progress:before {
        display: none;
    }
}

.podcast-progress .handle-progress-wrapper:hover .handle-progress {
    -webkit-transform: scale(2);
    transform: scale(2);
}

.title-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
    order: 0;
}

.podcast-text {
    color: var(--player-text-color);
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    font-family: "Roboto", -apple-system, "Open Sans", system-ui, "sans-serif";
    font-weight: 500;
    width: 100%;
}

.podcast-text:before,
.podcast-text:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    box-shadow: 0 0 50px var(--player-text-shadow), 0 0 40px var(--player-text-shadow), 0 0 30px var(--player-text-shadow), 0 0 20px var(--player-text-shadow),
    0 0 10px var(--player-text-shadow);
    z-index: 2;
}

.podcast-text:before {
    right: 100%;
}

.podcast-text:after {
    left: 100%;
}

.podcast-text > * {
    display: inline-block;
    vertical-align: top;
    padding-left: 100%;
    -webkit-animation: marquee 20s infinite linear;
    animation: marquee 20s infinite linear;
}

.media-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.time-container {
    order: 3;
}

.media-container-mobile,
.media-container-mobile-full {
    display: none;
}

@media only screen and (max-width: 739px) {
    .player {
        max-width: 665px;
        left: calc(50% - 333px);
    }
}

@media only screen and (max-width: 664px) {
    .player {
        left: 0;
        -moz-transition: height 1s ease;
        -webkit-transition: height 1s ease;
        -o-transition: height 1s ease;
        transition: height 1s ease;
        padding: 16px 24px 5px 24px;
    }

    .player-full {
        left: 0;
        padding-top: 24px;
    }

    .player .img-btn {
        position: absolute;
        top: -16px;
        left: calc(50% - 36px);
        padding: 0;
    }

    .player-full .img-btn {
        top: 56px;
    }

    .divide {
        position: absolute;
        width: 80px;
        top: 12px;
        border-top: 4px solid rgba(0, 0, 0, 0.1);
        left: calc(50% - 40px);
        display: none;
    }

    .player-full .divide {
        display: block;
    }

    .media-container {
        display: none;
    }

    .media-container-mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .action-bar {
        order: 0;
        display: flex;
        justify-content: space-between;
    }

    .player-full .media-container-mobile .action-bar {
        margin-bottom: 24px;
    }

    .action:first-child {
        margin: 0;
    }

    .action .action-btn:first-child {
        margin-right: 26px;
    }

    .action-btn img {
        width: 32px;
        height: 32px;
    }

    .media-container-mobile .action-btn img {
        width: 34px;
        height: 34px;
    }

    .player-full .media-container-mobile .action-btn img {
        width: 40px;
        height: 40px;
    }

    .title-container {
        margin-top: 10px;
        margin-bottom: 0;
        order: 1;
    }

    .time-container {
        order: 3;
    }

    .progress-container {
        order: 2;
        margin-top: 5px;
    }

    .player-full .title-container {
        margin-top: 0;
        margin-bottom: 20px;
        order: 0;
    }

    .player-full .action-bar {
        order: 1;
    }

    .player-full .progress-container {
        margin-top: 0;
    }

    .podcast-text, .player .silent-text {
        color: var(--player-text-color);
        width: 100%;
        font-weight: 400;
        font-size: 16px;
    }

    .podcast-text:before,
    .podcast-text:after {
        /*box-shadow: none;*/
    }

    /*!* TODO: need logic*!*/
    /*.media-container-mobile .time-container {*/
    /*  visibility: hidden;*/
    /*  opacity: 0;*/
    /*  display: none;*/
    /*  -webkit-transition: visibility 0.5s, opacity 0.5s linear;*/
    /*  transition: visibility 0.5s, opacity 0.5s linear;*/
    /*}*/
    /*.player-full .media-container-mobile .time-container {*/
    /*  display: flex;*/
    /*  opacity: 1;*/
    /*  justify-content: center;*/
    /*  margin-bottom: 20px;*/
    /*  margin-top: 20px;*/
    /*  visibility: visible;*/
    /*  -webkit-transition: visibility 0.5s, opacity 0.5s linear;*/
    /*  transition: visibility 0.5s, opacity 0.5s linear;*/
    /*}*/
}
