@charset "utf-8";

:root {
    --body-background: #ffffff;
    --text-color: #000000;
    --separator-color: rgba(118, 84, 154, 0.2);
    --podcast-link-separator-color: rgba(30, 139, 228, 0.1);
    --global-text-color: rgba(21, 21, 21, 1);
    --player-background: white;
    --player-text-shadow: white;
    --slider-rail-color: rgba(30, 139, 228, 0.3);
    --slider-track-color: #1e8be4;
    --player-text-color: black;
    --player-time-color: #999999;
    --player-timeline-color: rgba(30, 139, 228, 0.3);
    --player-loading-color: rgba(32, 70, 101, 0.15);
    --icon-color: black;
    --episode-header-background: white;
    --ring-color: #000000;
    --footer-background: #f3f3f3;
    --sub-background: #d9d9d9;
    --appearance-color: #151515;
    --block-background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #F3F3F3;
}

@media (prefers-color-scheme: dark) {
    :root {
        --body-background: #021422;
        --text-color: #C0D9ED;
        --global-text-color: #C0D9ED;
        --separator-color: #0A385C;
        --podcast-link-separator-color: #0A385C;
        --player-background: linear-gradient(180deg, #164469 0%, #0C314F 100%);
        --player-text-shadow: none;
        --player-text-color: #C0D9ED;
        --icon-color: #7B9FBC;
        --episode-header-background: #071F34;
        --player-time-color: #A7C6DF;
        --ring-color: #ffffff;
        --footer-background: #071F34;
        --sub-background: #071F34;
        --appearance-color: #C0D9ED;
        --block-background: linear-gradient(180deg, #164469 0%, #0C314F 100%);
    }
}

.light {
    --body-background: #ffffff;
    --text-color: #000000;
    --separator-color: rgba(118, 84, 154, 0.2);
    --podcast-link-separator-color: rgba(30, 139, 228, 0.1);
    --global-text-color: rgba(21, 21, 21, 1);
    --player-background: white;
    --player-text-shadow: white;
    --slider-rail-color: rgba(30, 139, 228, 0.3);
    --slider-track-color: #1e8be4;
    --player-text-color: black;
    --player-time-color: #999999;
    --player-timeline-color: rgba(30, 139, 228, 0.3);
    --player-loading-color: rgba(32, 70, 101, 0.15);
    --icon-color: black;
    --episode-header-background: white;
    --ring-color: #000000;
    --footer-background: #f3f3f3;
    --sub-background: #d9d9d9;
    --appearance-color: #151515;
    --block-background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #F3F3F3;
}

.dark {
    --body-background: #021422;
    --text-color: #C0D9ED;
    --global-text-color: #C0D9ED;
    --separator-color: #0A385C;
    --podcast-link-separator-color: #0A385C;
    --player-background: linear-gradient(180deg, #164469 0%, #0C314F 100%);
    --player-text-shadow: none;
    --player-text-color: #C0D9ED;
    --icon-color: #7B9FBC;
    --episode-header-background: #071F34;
    --player-time-color: #A7C6DF;
    --ring-color: #ffffff;
    --footer-background: #071F34;
    --sub-background: #071F34;
    --appearance-color: #C0D9ED;
    --block-background: linear-gradient(180deg, #164469 0%, #0C314F 100%);
}

html,
body {
    height: 100%;
    box-sizing: border-box;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased;
}

body {
    margin: 0;
    padding: 0;
    background: var(--body-background);
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--global-text-color);
    font-family: "Roboto Condensed", -apple-system, "Open Sans", system-ui,
    "sans-serif";
    -webkit-text-size-adjust: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

form,
fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

input,
select,
textarea,
button {
    font-weight: normal;
    font-size: 24px;
    line-height: normal;
    font-family: "Roboto Condensed", -apple-system, "Open Sans", system-ui,
    "sans-serif";
    color: var(--global-text-color);
    outline: none;
    text-align: left;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea,
select {
    -webkit-appearance: none;
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid rgba(114, 187, 247, 1);
    background: var(--body-background);
    margin: 0;
    padding: 25px 24px;
    width: 100%;
    display: block;
    -webkit-transition-property: border-color, box-shadow;
    transition-property: border-color, box-shadow;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

input:focus,
textarea:focus {
    border-color: var(--global-text-color);
}

input:invalid,
textarea:invalid {
    border-color: #f25c31;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;
    color: #9c9c9c;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: #9c9c9c;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;
    color: #9c9c9c;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
    opacity: 0.5 !important;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
    opacity: 0.5 !important;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
    opacity: 0.5 !important;
}

input::-ms-clear {
    display: none;
}

button::-moz-focus-inner {
    border: 0;
}

textarea {
    resize: none;
}

.outtaHere {
    overflow: hidden;
    max-width: 100vw;
    position: fixed !important;
    -webkit-transform: scale(0) !important;
    transform: scale(0) !important;
}

header,
nav,
section,
article,
aside,
footer,
menu,
time,
figure,
figcaption,
main {
    display: block;
}

img {
    border: 0;
    vertical-align: top;
}

a {
    color: #1e8be4;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media (pointer: fine) {
    a:hover,
    a:focus {
        color: #fc7e0f;
    }
}

a[href^="tel"] {
    color: inherit !important;
    cursor: inherit;
    text-decoration: none !important;
}

strong {
    font-weight: bold;
}

p,
ol,
ul {
    margin: 16px 0;
    padding: 0;
}

ol,
ul,
li {
    list-style: none;
}

li {
    position: relative;
    margin: 8px 0;
    padding: 0;
}

ul > li:before {
    content: "• ";
}

ol {
    counter-reset: li;
}

ol > li:before {
    counter-increment: li;
    content: counters(li, ".") ". ";
}

h1,
.h1,
h2,
.h2,
h3,
h4,
h5,
h6 {
    font-family: "Oswald", -apple-system, "Open Sans", system-ui, "sans-serif";
    font-weight: normal;
    font-size: 28px;
    line-height: 32px;
    margin: 32px 0 16px 0;
}

h1,
.h1 {
    font-size: 40px;
    line-height: 40px;
}

big,
.big {
    font-size: 24px;
    line-height: 32px;
    font-family: Roboto Condensed, -apple-system, Open Sans, system-ui, "sans-serif";
    font-weight: 400;
    margin: 16px 0;
    padding: 0;
}

small,
.small {
    font-size: 14px;
    line-height: 20px;
    color: var(--global-text-color);
}

.input {
    position: relative;
}

.input input {
    padding-right: 64px;
}

.input .clear {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: -16px 24px;
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.input.placeholder .clear {
    -webkit-transform: scale(0);
    transform: scale(0);
}

.input .clear svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    fill: rgba(32, 70, 101, 0.3);
}

@media (pointer: fine) {
    .input .clear:hover svg {
        fill: #fc7e0f;
    }
}

.btn {
    -webkit-appearance: none;
    display: inline-block;
    vertical-align: top;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
    text-decoration: none;
    border: 0;
    outline: none;
    border-radius: 8px;
    color: #fff;
    background: rgba(114, 187, 247, 1);
    box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.1);
    padding: 24px;
    margin: 0;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.03em;
    font-family: "Roboto Condensed", -apple-system, "Open Sans", system-ui,
    "sans-serif";
    text-transform: none;
    white-space: normal;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.btn:not(:disabled),
.btn:not(.disabled) {
    cursor: pointer;
}

@media (pointer: fine) {
    .btn:hover,
    .btn:focus {
        box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.1),
        0 8px 24px rgba(32, 70, 101, 0.3);
    }
}

.btn:disabled,
.btn.disabled .lang-btn:disabled,
.lang-btn.disabled {
    background: rgba(32, 70, 101, 0.3);
    cursor: default;
}

.break-word {
    word-break: break-all;
}

.nowrap {
    white-space: nowrap;
}

.uppercase {
    text-transform: uppercase;
}

.red {
    color: #ce262f;
}

.gray {
    color: rgba(21, 21, 21, 0.7);
}

@media (pointer: coarse) {
    .hide-on-touch {
        display: none;
    }
}

@media (pointer: fine) {
    .show-on-touch {
        display: none;
    }
}

.mainwrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
    min-height: 100%;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
}

.flexible {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}

.content {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}

.header,
.footer {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    /*height: 100%;*/
}

.container {
    width: 100%;
    margin: 0 auto;
    max-width: 640px;
    position: relative;
    padding: 0 20px;
}

.developers {
    display: flex;
    justify-content: space-between;
}

.content {
    padding: 40px 0;
    position: relative;
    z-index: 2;
}

.footer {
    padding: 24px 0 48px 0;
    flex: 0 0 auto;
    background: var(--footer-background);
    display: flex;
    justify-content: center;
}

.actions {
    display: flex;
    justify-content: space-between;
}

/*.footer-bg {*/
/*	border-radius: 16px;*/
/*	padding: 28px 32px;*/
/*	background: rgba(114, 187, 247, 0.07);*/
/*	text-align: center;*/
/*}*/
.footer-bg > * {
    margin: 8px 0;
}

.footer-bg > *:first-child {
    margin-top: 0 !important;
}

.footer-bg > *:last-child {
    margin-bottom: 0 !important;
}

.big-logo {
    margin: 0 0 40px 0;
}

.big-logo:after {
    content: " ";
    clear: both;
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;
    font-size: 0;
}

.big-logo img {
    width: auto;
    /*height: auto;*/
    max-width: 100%;
    max-height: 40px;
}

.big-logo .err-img {
    float: left;
}

.big-logo .err-img + * {
    float: right;
}

.search-block {
    margin: 40px 0 0 0;
    padding: 16px;
    background: var(--body-background);
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.search-block.style-error {
    background-color: #fff8f8;
    box-shadow: inset 0 0 0 4px #d50000, 0px 16px 40px rgba(12, 36, 55, 0.15);
}

.search-block .submit {
    margin: 16px 0 0 0;
}

.search-block .submit .btn {
    width: 100%;
}

.page-loading {
    margin: 56px 0;
    text-align: center;
}

.page-loading img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 145px;
}

.results-text {
    margin: 32px 0 16px 0;
    text-align: center;
}

.results-loading {
    margin: 32px 0 22px 0;
    text-align: center;
}

.results-loading img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 145px;
}

.results {
    margin: 24px 0 -16px 0;
}

.results .info {
    margin: 24px 0;
}

.results .list {
    border-width: 0;
    margin: 0 -16px;
}

.list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.list-el {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 20px;
    line-height: 24px;
    padding: 16px;
    -webkit-transition: box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media (pointer: fine) {
    .card:hover {
        box-shadow: 0 16px 40px rgba(0, 0, 0, 0.1);
    }
}

.list-el:last-child .card {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.card a {
    color: rgba(114, 187, 247, 1);
}

@media (pointer: fine) {
    .card a:hover,
    .card a:focus {
        color: #1e8be4;
    }
}

.card .img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 128px;
    margin: 0 24px 0 0;
    display: block;
}

.card .img img {
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.card .text {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.card .h2 {
    padding: 0 0 16px 0;
    margin: 0 0 auto 0;
    min-height: 64px;
}

.card .h2 a {
    color: inherit;
}

.table-simple {
    margin: 0 -8px;
}

.table-simple table {
    border-collapse: collapse;
    width: 100%;
    border: 0;
    margin: 0;
}

.table-simple td {
    text-align: left;
    vertical-align: top;
    padding: 0 8px;
    border: 0;
}

.table-simple .w0 {
    width: 1px;
}

.wrap-copy {
    position: relative;
}

.wrap-copy .popup {
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 5;
    background: #fc7e0f;
    box-shadow: 0px 4px 8px rgba(62, 35, 11, 0.2);
    border-radius: 4px;
    padding: 6px 12px;
    white-space: nowrap;
    margin: 8px 0;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.wrap-copy.copied .popup {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.link-copy {
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    margin: 0 -4px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.link-copy svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    fill: rgba(114, 187, 247, 1);
}

@media (pointer: fine) {
    .link-copy:hover svg {
        fill: #1e8be4;
    }
}

.copied .link-copy svg {
    fill: #fc7e0f;
}

.episode-action-bar .episode-action-bar-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: none;
}

.episode-action-bar path {
    stroke-width: 2.5;
}

.episode-action-bar .link-copy {
    width: 30px;
    height: 32px;
    margin-right: 5px;
}

.episode-action-bar .link-copy svg {
    fill: #1E8BE4;
}

@media (pointer: fine) {
    .episode-action-bar .link-copy:hover svg {
        fill: #fc7e0f;
    }
}

.episode-action-bar .copied .link-copy svg {
    fill: #fc7e0f;
}

.services-list {
    font-family: "Roboto", -apple-system, "Open Sans", system-ui, "sans-serif";
    text-align: center;
    margin: 32px 0 56px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.services-list ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 20px -12px -24px -12px;
    padding: 0;
}

.services-list li:before {
    display: none;
}

.services-list li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin: 0 12px 24px 12px;
    padding: 0;
}

.services-list a {
    display: block;
}

.services-list img {
    height: 48px;
    width: auto;
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

@media (pointer: fine) {
    .services-list a:hover img,
    .services-list a:focus img {
        -webkit-transform: translate3d(0, -8px, 0);
        transform: translate3d(0, -8px, 0);
    }
}

.soc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -8px;
}

.soc > * {
    margin: 8px;
    border-radius: 50%;
}

.soc img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

@-webkit-keyframes marquee {
    0% {
        -webkit-transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(-100%, 0);
    }
}

@keyframes marquee {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
    }
}

@-webkit-keyframes loading {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 7px 0;
    }
}

@keyframes loading {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 7px 0;
    }
}

.item {
    margin: 40px 0 0 0;
    background: var(--body-background);
    border-radius: 16px;
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.1);
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.item > * {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.item-top {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: absolute;
    top: -32px;
    left: 0;
    right: 0;
    line-height: 0;
    margin: 0 0 0 223px;
}

.item-top .link-share {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
}

.item-top .link-share:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
}

.item-top .link-share svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.item-top .link-share svg:nth-child(1) {
    opacity: 1;
    fill: #000;
    max-width: 100%;
    max-height: 100%;
}

.item-top .link-share svg:nth-child(2) {
    opacity: 0;
    fill: #f25c31;
}

.item.open-share .item-top .link-share svg:nth-child(1) {
    opacity: 0;
}

.item.open-share .item-top .link-share svg:nth-child(2) {
    opacity: 1;
}

.item-top .logo img {
    width: auto;
    height: auto;
    max-height: 16px;
}

.item-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 240px;
    height: 240px;
    margin: -32px;
    z-index: 5;
    -webkit-perspective: 600px;
    perspective: 600px;
}

.item-card .wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.item:not(.open-share) .item-card .wrap {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.item-card .wrap > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--body-background);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 8px;
}

.item-card .img {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.item-card .img img {
    width: 100%;
    height: 100%;
}

.item-card .share {
    text-align: center;
    padding: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.item-card .share .big {
    color: #f25c31;
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 16px 0;
}

.item-card .share pre {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background: none;
    padding: 6px 8px;
    border-radius: 4px 4px 0 0;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    border-color: rgba(114, 187, 247, 1);
    margin: 16px 0 0 0;
    height: 88px;
    overflow: hidden;
    overflow-y: auto;
    display: block;
    white-space: normal;
    word-break: break-all;
    text-align: left;
    outline: none;
    font-family: "Roboto Mono", monospace;
    font-size: 10px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.5);
}

.item-card .share .btn {
    display: block;
    width: 100%;
    border-radius: 0 0 4px 4px;
    box-shadow: none;
    font-size: 14px;
    line-height: 24px;
    padding: 4px;
    margin: 0;
}

.item-player {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 208px;
    padding: 32px 24px 16px 14px;
    min-height: 155px;
}

.item-body {
    color: #fff;
    background: rgba(114, 187, 247, 1);
    position: relative;
}

.item-body a {
    color: rgba(255, 255, 255, 0.7);
}

.item-body h1,
.item-body .h1 {
    margin: 0 0 8px 0;
}

.item-title {
    position: relative;
    padding: 64px 64px 24px 24px;
}

.item-body .link-descr {
    width: 32px;
    height: 32px;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border-radius: 50%;
    margin: 17px 19px;
}

.item-body .link-descr svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(0deg);
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    fill: #fff;
}

.item.open-descr .item-body .link-descr svg {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(-180deg);
    transform: translate3d(-50%, -50%, 0) rotate(-180deg);
}

.item-body .item-descr {
    display: none;
}

.item.open-descr .item-body .item-descr {
    display: block;
}

.item-body .item-descr .ind {
    border-style: solid;
    border-width: 1px 0 0 0;
    border-color: rgba(255, 255, 255, 0.3);
    padding: 16px 24px;
}

.req-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 24px 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    opacity: 0;
}

.req-popup.open {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    z-index: 101;
    padding-top: 0;
}

.req-popup .bg {
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    border-radius: 16px;
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.1);
    padding: 24px 24px 12px 24px;
    text-align: center;
}

.req-popup .top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    margin: 0 0 24px 0;
}

.req-popup .img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    margin: 0 24px 0 0;
    position: relative;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.req-popup .img img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
}

.req-popup .link {
    display: block;
    font-size: 20px;
    line-height: 24px;
    color: var(--global-text-color);
    background: var(--body-background);
    box-shadow: 0 8px 24px rgba(32, 70, 101, 0.15);
    padding: 12px;
    border-radius: 8px;
    text-align: center;
}

.req-popup .no {
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin: 12px 0 0 0;
    display: inline-block;
    vertical-align: top;
}

@media (pointer: fine) {
    .req-popup .no:hover {
        text-decoration: underline;
    }
}

/* info page */

.podcast-links {
    display: block;
}

.item-links {
    display: none;
}

/* search page */
.text-desktop {
    display: block;
}

.text-mobile {
    display: none;
}

.podcast-header {
    position: relative;
    padding: 40px 0;
}

.podcast-info-wrapper {
    display: flex;
}

.episode-description {
    position: relative;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
    /*margin-left: 40px;*/
    word-break: break-word;
}

.episode-description h1 {
    font-size: 32px;
}

.episode-description div {
    color: #999999;
}

.episode-header .container .img-btn {
    position: absolute;
    top: 40px;
    right: 38px;
}

.podcast-logo {
    position: absolute;
    left: -198px;
}

.podcast-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 24px;
}

.podcast-info h1 {
    font-size: 40px;
    margin-bottom: 16px;
}

.podcast-author {
    display: flex;
    justify-content: flex-start;
}

.podcast-author a:hover,
.podcast-author a:active {
    color: #fc7e0f !important;
}

@media (pointer: fine) {
    .podcast-author a:hover,
    .podcast-author a:active {
        color: #fc7e0f !important;
    }
}

.podcast-info > div,
.podcast-info h1 {
    color: white;
    margin-top: 0;
}

@media only screen and (max-width: 1048px) {
    .podcast-logo {
        position: relative;
        margin-bottom: 24px;
        left: 0;
        display: block;
        margin-left: 0px;
    }

    .episode-header .podcast-logo {
        margin-left: 40px !important;
    }

    .episode-header .container .img-btn {
        top: 88px;
    }
}

.podcast-img-wrapper {
    width: 288px;
    height: 288px;
    border-radius: 8px;
    background: url(../public/img/rings.svg) center center no-repeat;
}

.podcast-img {
    width: 288px;
    height: 288px;
    border-radius: 8px;
    box-shadow: 0px 16px 32px -8px rgba(0, 0, 0, 0.2);
}

.tabs {
    display: flex;
    margin: 0;
}

.tab {
    padding: 0;
    background: none;
    outline: none;
    border: 0;
    font-size: 20px;
    cursor: pointer;
}

.tabs li {
    margin-bottom: 0px;
    margin-top: 0px;
    white-space: nowrap;
}

.tabs .tab:not(:last-child) {
    margin-right: 24px;
}

.tabs li:before {
    content: "";
}

.podcast-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 40px;
    padding-bottom: 8px;
}

.episode-wrapper {
    padding-top: 16px;
    padding-bottom: 16px;
}

.episode-wrapper .episode-container {
    padding-bottom: 24px;
}

.episode-wrapper .episode-container:not(:last-child) {
    border-bottom: 1px solid var(--podcast-link-separator-color);
}

/* episode page */

.episode-header .container {
    max-width: 740px;
    padding: 0;
    z-index: 1;
}

.episode-header {
    padding: 0;
    padding-top: 40px;
}

.episode-header-content {
    border-radius: 20px 20px 0 0;
    background-color: var(--episode-header-background);
    padding: 40px;
    padding-bottom: 0;
}

.episode-page {
    max-width: 740px;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
    padding: 0 0 40px 0;
}

.episode-page .spacer {
    background-color: var(--episode-header-background);
    display: block;
    width: 100%;
    height: 40px;
    min-height: 40px;
}

.episode-page .services-list {
    margin: 0;
    padding: 24px 56px;
    border-bottom: 1px solid var(--separator-color);
    border-top: 1px solid var(--separator-color);
    margin-bottom: 24px;
}

.episode-page p {
    padding: 0 40px;
    margin: 0;
}

/* about page */

.about {
    margin-top: 40px;
}

.resources {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding-top: 24px;
    padding-bottom: 8px;
}

.episode-page .resources {
    padding-left: 40px;
    padding-right: 40px;
}

.resources .resource {
    padding-bottom: 32px;
}

.hidden {
    display: none;
}

.footer {
    .left-actions {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}
