.clear-cache {
    margin-bottom: 20px;
    text-align: center;
}

.clear-cache a {
    color: #9c9c9c;
    cursor: pointer;
    -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media (pointer: fine) {
    .clear-cache a:hover,
    .clear-cache a:focus {
        color: #fc7e0f;
    }
}

.clear-cache p {
    margin: 0;
}
