.modal-container {
  height: 100%;
  overflow: auto;
  position: absolute;
  background-color: var(--body-background);
}

/* HEADER */

.header {
  display:  flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  padding: 21px;
}

.title {
  font-family: 'Oswald';
  font-size: 32px;
  line-height: 32px;
  color: var(--text-color);
  padding-bottom: 10px;
}

.modal-close {
  margin-left: auto;
  cursor:  pointer;
}

.modal-close > img {
  width: 16px;
  height: 16px;
}

/* PREVIEW */

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;

  background-image: url('../../assets/img/settings-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 268px;
}

.preview.podcast {
  height: 436px;
}

.preview-container {
  display: flex;
  flex-direction: column;
}
.preview-container.podcast {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.preview-episodes {
  background-color: #fff;
  border-radius: 0 8px 8px 0;
  padding: 16px 0 12px 0;
}
.preview-episodes.platforms-on {
  border-radius: 0 8px 0 0;
}

.preview-block {  
  display: flex;
  border-radius: 8px;
  padding: 16px;
  gap: 8px;
  background-repeat: round !important;
  justify-content: space-between;
}
.preview-block.platforms-on {
  border-radius: 8px 8px 0 0;
}
.preview-block.podcast {
  flex-direction: column;
  border-radius: 8px 0 0 8px;
}
.preview-block.podcast.platforms-on {
  border-radius: 8px 0 0 0;
}
.preview-left {
  display: flex;
  flex-direction: column;
}
.preview-top {
  display: flex;
  flex-direction: row;
  gap: 64px;
}
.preview-title-block {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.preview-title {
  width: 156px;
  height: 16px;

  background: #D9D9D9;
  opacity: 0.5;
  border-radius: 3px;
}
.preview-subtitle {
  width: 69px;
  height: 8px;

  background: #D9D9D9;
  opacity: 0.5;
  border-radius: 2px;
}
.preview-btn {
  width: 21px;
  height: 8px;

  border-radius: 2px;
}
.preview-player {
  margin-top: 12px;
  margin-bottom: 34px;
  display: flex;
  gap: 4px;
  align-items: center;
}
.preview-player-button {
  display: flex;
  align-items: center;
}
.preview-player-bar {
  height: 8px;
  width: 128px;

  border-radius: 2px;
}
.preview-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.preview-bottom-text {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.preview-bottom-season {
  width: 21px;
  height: 8px;

  background: #D9D9D9;
  opacity: 0.5;
  border-radius: 2px;
}
.preview-bottom-episode {
  width: 34px;
  height: 8px;

  background: #D9D9D9;
  opacity: 0.5;
  border-radius: 2px;
}
.preview-bottom-logo {
  display: flex;
}
.preview-bottom-pc {
  color: #000000;
}
.preview-links {
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  width: 403px;
  height: 32px;
  display: flex;
  justify-content: center;
}
.preview-links > img {
  width: 280px;
}
.preview-container.podcast .preview-links {
  width: 420px;
}
.preview-logo-container {
  position: relative;
  min-width: 110px;
}
.preview-logo-image {
  position: absolute;
  width: 115px;
  border-radius: 5px;
  filter: drop-shadow(0px 5.83957px 11.6791px rgba(0, 0, 0, 0.4));
}

.podcast .preview-logo-image {
  width: 155px;
}

.preview-size {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: flex;
  gap: 4px;
  padding: 2px 8px;
}
.preview-width, .preview-height, .preview-x {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  color: #FFFFFF;
}

/* APPEARENCE */

.appearence {
  padding: 16px;
}

.appearence-title {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: var(--appearance-color);

  margin-bottom: 16px;
}

.appearence-block {
  margin-bottom: 8px;
}

.appearence-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  padding-right: 25px;
  background: var(--footer-background);
  border-radius: 8px;
}

.appearence-item.show-settings {
  border-radius: 8px 8px 0 0;
}

.custom-label {
  display: flex;
  align-items: end;
  gap: 6px;
}

.custom-label-divider {
  color: #D6D6D6;
}

.custom-dropdown-icon {
  width: 16px;
  height: 8px;
  position: absolute;
  right: 7px;
  pointer-events: none;
}

/* APPEARENCE SETTINGS */

.appearence-item-settings.show {
  display: flex;
  flex-direction: column;
  padding: 28px 24px;
  background: var(--block-background);
  border-radius: 0 0 8px 8px;
}
.appearence-item-settings {
  display: none;
}

.appearence-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;  
  width:  190px;
}

/* width settings */
.appearence-item-settings.width {
  flex-direction: row;
  align-items: center;
}

.appearence-label-text {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: var(--text-color);
  width: 170px;
  align-items: center;
  display: flex;
}

.appearence-label-width {
  width: 50px;
  height: 22px;
  padding:  4px 10px 4px 8px;

  font-family: 'Roboto Condensed';
  background: var(--player-background);
  font-size: 16px;

  border: 1px solid #1E8BE4;
  border-radius: 4px;
}

.appearence-label-height, .appearence-label-x {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color);
}

.appearence-label-x {
  margin: 0 4px;
}

/* color settings */
.appearence-label-title {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color);

  margin-bottom: 4px;
}

.appearence-color-background-container {
    display: flex;
    gap: 33px;
}
.appearence-color-background-container.background {
    margin-bottom: 24px;
}

.color-label {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color);

  margin-bottom: 4px;
}
.color-list {
  display: flex;
  gap: 8px;
}

.color-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  cursor: pointer;
}

.color-checkmark {
  display: none;
}
.color-checkmark.invert {
  filter: invert(100%);
}
.color-checkmark.show {
  display: block;
}

.color-custom {
  background-image: url('../../assets/img/custom-color-bg.svg');
  background-repeat: no-repeat;
}

/* color picker */
.color-picker-header {
  padding: 12px 16px;
}
.color-picker-title {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: var(--text-color);
  padding-bottom: 0;
}
.color-picker-close {
  position: unset !important;
}

.color-picker-subtitle {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color);
  margin-left: 16px;
}

.rcp-body {
  padding: 16px;
}
.rcp-saturation {
  border-radius: 0;
}
.hex-element {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}
.rcp-fields-element-label {
  order: 1;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color);
}
.rcp-fields-element-input {
  order: 2;
  padding: 4px 16px 4px 8px;
  gap: 8px;

  width: 153px;
  height: 32px;

  background: var(--body-background);
  border: 1px solid #1E8BE4;
  border-radius: 4px;

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color);
  text-align: left;
}

.color-btn {
  width: 204px;
  margin: auto;
  margin-bottom: 16px;
  justify-content: center;
}

/* image settings */
.appearence-image-input{
  display: flex;
  margin-bottom: 34px;
}
.appearence-image-label {
  width: 240px;
}
.appearence-label-image {
  width: 100%;
  height: 22px;
  padding:  4px 10px 4px 8px;

  font-family: 'Roboto Condensed';
  background: var(--body-background);
  font-size: 16px;

  border: 1px solid #1E8BE4;
  border-radius: 4px;
}

.appearence-image-buttons {
  display: flex;
}
.image-buttons {
  display: flex;
  margin-left: auto;
  gap: 20px;
}
.image-btn {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  background: transparent;
  color: #1E8BE4;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.crop-block {
    width: 100%;
    display: flex;
    justify-content: center;
}
.ReactCrop__child-wrapper>img, .ReactCrop__child-wrapper>video {
  max-height: 400px;
}

.image-controls {
  padding: 16px;
  display: flex;
}
.image-slider {
  width: 324px;
  margin-left: auto;
  display: flex;
  gap: 10px;
  align-items: center;
}

.image-scale {
  width: 20px;
  cursor: pointer;
}


/* CODE SECTION */

.code-section {
  padding: 16px;
  background: var(--footer-background);
}

.code-section-title {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: var(--appearance-color);
  margin-bottom: 16px;
}

.code-container {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color);
  opacity: 0.9;

  padding: 8px 16px;
  background: var(--body-background);
  border: 1px solid #1E8BE4;
  border-radius: 4px;
  margin-bottom: 16px;
}

.copy-btn, .checkmark-btn {
  background: #1E8BE4;
  border: 1px solid #1E8BE4;
  border-radius: 4px;

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;

  padding: 14px 31px;
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;
  min-width: 180px;
}

.checkmark-btn {
  background-color: #1CCB19;
  border: 1px solid #1CCB19;
}