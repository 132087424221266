.actionBar {
    display: flex;
    align-items: center;
    min-height: 32px;
    order: 0;
    justify-content: space-between;
}

.action {
    display: flex;
    flex-wrap: nowrap;
}

.action:first-child {
    margin: 0;
}

.action .actionBtn:first-child {
    margin-right: 26px;
}

.action .actionBtn {
    outline: none;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
}

.fillColor {
    fill: var(--icon-color);
}

.strokeColor {
    stroke: var(--icon-color);
}

.action .actionBtn img {
    width: 34px;
    height: 34px;
    /*fill: #7B9FBC;*/
}

