ul.pagination {
    display: inline-block;
    padding: 0;
    margin: 0 auto;
}

ul.pagination li {
    display: inline;
}

ul.pagination li a {
    color: var(--text-color);
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border-radius: 20px;
    margin: 0 3px;
}

ul.pagination li:before {
    content: none;
}

ul.pagination li.active a {
    background-color: #1e8be4;
    color: white;
}

ul.pagination li.disabled {
    display: none;
}

ul.pagination li a:hover:not(.active) {
    background-color: #ddd;
    color: black;
}

.pagination-wrapper {
    text-align: center;
    margin-bottom: 20px;
}
