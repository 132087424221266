.episode-container {
  display: flex;
  flex-direction: column;
}

.episode-container .img-btn:hover svg path {
  stroke: #fc7e0f;
}

.episode-info {
  color: #999999;
}

.episode-action-bar {
  display: flex;
  justify-content: space-between;
}

.play-episode {
  color: #1e8be4;
  font-size: 16px;
}

.play-episode span {
  margin-left: 8px;
}

.episode-link {
  color: var(--text-color);
}

.episode-link:hover {
  cursor: pointer;
  color: #1e8be4;
}

.episode-descr {
  line-height: 16px;
}

.episode-link h2 {
  margin-top: 24px;
}

@media only screen and (max-width: 664px) {
  .episode-link h2 {
    margin-bottom: 12px;
    line-height: 28px;
  }

  .episode-descr {
    margin-top: 8px;
  }
}
