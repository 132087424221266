.episode-page .episode-description {
    padding: 0 40px;
}

.episode-page .episode-description p {
    padding: 0;
}

.episode-page .episode-description li:before {
    content: "";
}

.play-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.episode-header .container .play-wrapper .img-btn {
    position: relative;
    top: unset;
    right: unset;
}

.play-wrapper .episode-date {
    line-height: 19px;
}

@media only screen and (max-width: 664px) {
    .episode-header .container .play-wrapper .img-btn {
        top: unset;
        right: unset;
        position: relative;
        transform: none;
    }
}

.btn-link {
    color: #1e8be4;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    border: none;
    background: none;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    font-family: "Roboto Condensed", -apple-system, "Open Sans", system-ui, "sans-serif";
    -webkit-text-size-adjust: none;
    margin: 0;
    padding: 0;
}

@media (pointer: fine) {
    .btn-link:hover,
    .btn-link:focus {
        color: #fc7e0f;
    }
}
.btn-link[href^="tel"] {
    color: inherit !important;
    cursor: inherit;
    text-decoration: none !important;
}

.episode-header-description-wrapper {
    position: relative;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    word-break: break-word;
}

.episode-header-description {

}

.episode-podcast-link {
    padding-right: 50px;
}

.episode-header-description h1 {
    font-size: 32px;
    margin: 10px 0 16px 0;

}

.arrow-stroke {
    stroke: var(--text-color);
}
