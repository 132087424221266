:root {
    --language-background: white;
    --language-text-color: #1e8be4;
    --language-sub-background: white;
    --language-active-text-color: #1e8be4;
}

@media (prefers-color-scheme: dark) {
    :root {
        --language-background: #1e8be4;
        --language-text-color: white;
        --language-sub-background: #021422;
        --language-active-text-color: #1e8be4;
    }
}

.language {
    /* TODO: next line should be deleted*/
    position: relative;
    background: var(--language-background);
    border-radius: 20px;
    padding: 4px 12px 4px 4px;
    color: var(--language-text-color);
    font-size: 20px;
    box-shadow: none;
    font-weight: 300;
    display: flex;
    align-items: center;
    vertical-align: top;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
    text-decoration: none;
    border: 0;
    outline: none;
    margin: 0;
    line-height: 20px;
    font-family: "Roboto Condensed", -apple-system, "Open Sans", system-ui,
    "sans-serif";
    text-transform: none;
    white-space: normal;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.language svg path {
    stroke: var(--language-text-color);
}

.language:not(:disabled),
.language:not(.disabled) {
    cursor: pointer;
}

.active-language,
.active-language:active,
.active-language:focus {
    background: var(--language-sub-background);
    color: var(--language-active-text-color);
}

.language svg, .active-language svg {
    margin-right: 8px;
}

.active-language svg path,
.language:focus svg path {
    stroke: var(--language-active-text-color);
}

/* TODO: should be deleted */
.modal-wrapper {
    z-index: 3;
    position: absolute;
    bottom: 48px;
    right: 0;
}

/* TODO: end*/

.modal {
    line-height: 40px;
    background: var(--language-sub-background);
    border-radius: 8px;
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.15);
    padding: 8px 16px;
    margin: 0;
}

.modal li {
    white-space: nowrap;
    text-align: left;
    margin: 0;
}

.modal li:before {
    content: "";
}

.modal-btn {
    margin: 0;
    color: var(--text-color);
    outline: none;
    background: var(--language-sub-background);
    padding: 0;
    max-width: 100%;
    text-decoration: none;
    border: 0;
    font-size: 20px;
}

.modal-btn:active,
.modal-btn:hover {
    color: var(--language-active-text-color);
    cursor: pointer;
}

.modal-btn:active {
    font-weight: bold;
}

@media only screen and (max-width: 664px) {
    .language {
        line-height: 16px;
    }
}