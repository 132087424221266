.themeChanger {
    cursor: pointer;

    position: relative;
    background: var(--language-background);
    border-radius: 20px;
    padding: 5px;
    color: var(--language-text-color);
    box-shadow: none;
    display: flex;
    align-items: center;
    vertical-align: top;
    border: 0;
    outline: none;
    margin: 0;
    white-space: normal;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
}

#svg-auto-light, #svg-auto-dark, #svg-light, #svg-dark {
    display: none;
    width: 30px;
}

#svg-auto-light {
    display: block;
}

@media (prefers-color-scheme: dark) {
    #svg-auto-light, #svg-light, #svg-dark {
        display: none;
    }

    #svg-auto-dark {
        display: block;
    }
}

.light {
    #svg-auto-light, #svg-auto-dark, #svg-dark {
        display: none;
    }

    #svg-light {
        display: block;
    }
}

.dark {
    #svg-auto-light, #svg-auto-dark, #svg-light {
        display: none;
    }

    #svg-dark {
        display: block;
    }
}
