.logo {
    height: 40px;
}

.logoColorFill {
    fill: var(--ring-color);
}

@media only screen and (max-width: 664px) {
    .logo {
        height: 33px;
    }
}