.service-link {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 32px;
  color: var(--text-color);
  width: fit-content;
}

.service-img img {
  margin-right: 16px;
  height: 48px;
  width: auto;
  backface-visibility: hidden;
}

@media (pointer: fine) {
  link .resource:hover,
  link .resource-mob:hover,
  link .resource:focus,
  link .resource-mob:focus {
    color: #1e8be4;
    background: rgba(68, 157, 229, 0.1);
  }
}

.animation {
  transition: transform 0.8s;
}

.animation:hover {
  transform: translate(0px, -8px);
}
