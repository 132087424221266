.resource-link {
  margin-bottom: 32px;
  overflow: hidden;
  background:linear-gradient(transparent 270px, white);
}

.resource {
  display: flex;
  align-items: center;
}

.resources .resource-link .resource {
  padding-bottom: 0;
}

.resources .resource-link {
  position: relative;
}

.resources .resource-link .resource:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 20px;
  z-index: 2;
  /*width: 100%;*/
  /*height: 100%;*/
  box-shadow: inset -20px 0 20px -20px var(--body-background);
}

.resource-info {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.resource-info span {
  color: var(--text-color);
}

.resource-info a {
  white-space: nowrap;
}

/*.resource-info a:before,*/
/*.resource-info a:after {*/
/*  content: "";*/
/*  position: absolute;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*  width: 100px;*/
/*  box-shadow: 0 0 50px #fff, 0 0 40px #fff, 0 0 30px #fff, 0 0 20px #fff,*/
/*  0 0 10px #fff;*/
/*  z-index: 2;*/
/*}*/
/*.resource-info a:before {*/
/*  right: 100%;*/
/*}*/
/*.resource-info a:after {*/
/*  left: 100%;*/
/*}*/

.link-content {
  line-height: 20px;
}

@media only screen and (max-width: 664px) {
  .link-content {
    padding: 16px 20px !important;
  }

  .resource-link {
    color: #1e8be4 !important;
    margin-bottom: 0px;
  }

  .resource:hover,
  .resource-mob:hover,
  .resource:focus,
  .resource-mob:focus {
    background: rgba(68, 157, 229, 0.1);
  }
}
