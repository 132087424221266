@charset "utf-8";

@media only screen and (max-width: 664px) {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="color"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea,
  select {
    padding: 17px 16px;
  }
  h2,
  .h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 24px;
    line-height: 24px;
  }
  big,
  .big {
    font-size: 16px;
    line-height: 24px;
  }
  .input input {
    padding-right: 48px;
  }
  .input .clear {
    margin-right: 16px;
  }
  .btn {
    padding: 16px;
  }
  .container {
    max-width: none;
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer {
    padding-bottom: 0;
    font-size: 16px;
    line-height: 16px;
  }
  .footer-bg {
    border-radius: 0;
    margin: 0 -100vw;
    padding: 32px 100vw 40px 100vw;
  }
  .footer.is-covered .footer-bg {
    padding-top: 56px;
  }
  .footer-bg > * {
    margin: 16px 0;
  }
  .developers {
    flex-direction: column;
  }
  .big-logo {
    margin: 16px 0 21px 0;
    text-align: center;
  }
  .big-logo img {
    max-height: 33px;
  }
  .search-block {
    margin-top: 24px;
  }
  .search-block.show-results {
    margin-bottom: -64px;
  }
  .search-block .input input {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
  .results {
    margin-top: 16px;
  }
  .results .info {
    margin: 16px 0;
  }
  .results .list {
    border-width: 0;
    margin: 0 -16px;
  }
  .card {
    font-size: 16px;
    line-height: 20px;
  }
  .card .img {
    width: 80px;
    margin-right: 16px;
  }
  .card .h2 {
    min-height: 52px;
    padding-bottom: 6px;
  }
  .table-simple {
    margin: 0 -4px;
  }
  .table-simple table {
    width: 100%;
  }
  .table-simple td {
    padding: 0 4px;
  }
  .services-list {
    margin-bottom: 0;
  }
  .services-list ul {
    margin-top: 24px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .services-list li {
    margin-left: 10px;
    margin-right: 10px;
  }
  .services-list img {
    height: 40px;
  }
  .episode-page .services-list img {
    height: 48px;
  }
  .item {
    margin: -40px -20px 60px -20px;
    padding: 44px 20px 64px 20px;
    background: rgba(114, 187, 247, 0.07);
    border-radius: 0;
    box-shadow: none;
  }
  .item > * {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .item-top {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    position: static;
    top: auto;
    left: auto;
    right: auto;
    margin: 0 0 20px 0;
  }
  .item-top .link-share {
    width: 24px;
    height: 24px;
  }
  .item-card {
    position: relative;
    top: auto;
    left: auto;
    margin: 0 auto -64px auto;
  }
  .item-player {
    background: var(--body-background);
    border-radius: 8px;
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.1);
    margin: -8px 0 -190px 0;
    min-height: 0;
    padding: 20px 16px 16px 16px;
  }
  .item-body {
    margin: 0 -20px;
    padding-bottom: 42px;
  }
  .item-body h1,
  .item-body .h1 {
    margin: 0 0 8px 0;
  }
  .item-title {
    text-align: center;
    position: static;
    padding: 80px 20px 16px 20px;
  }
  .item-body .link-descr {
    height: 42px;
    width: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }
  .item-body .link-descr svg {
    top: 8px;
  }
  .item-body .item-descr .ind {
    padding: 16px 20px 0 20px;
  }
  .item-links {
    background: none;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .item-links .link {
    font-size: 16px;
    padding: 20px 64px;
  }
  .item-links .link .arr {
    margin-right: 16px;
  }
  .item-links .link .img {
    margin: -20px 12px;
    width: 40px;
    height: 40px;
  }
  /* search page */

  .text-desktop {
    display: none;
  }
  .text-mobile {
    display: block;
  }

  .podcast-img-wrapper {
    width: 240px;
    height: 240px;
  }


  .podcast-img {
    width: 240px;
    height: 240px;
  }

  /* main page*/
  .podcast-logo {
    width: 76px;
    height: 16px;
  }

  .podcast-info {
    align-items: center;
    margin-left: 0;
    margin-top: 24px;
  }

  .podcast-info div:first-child {
    margin-bottom: 24px;
  }

  .podcast-info h1 {
    font-size: 32px;
    margin-bottom: 8px;
    text-align: center;
  }

  .podcast-header {
    padding: 24px 0;
  }

  .podcast-author {
    display: flex;
    justify-content: center;
  }

  .main-page .container {
    padding-left: 0;
    padding-right: 0;
  }

  .main-page .episode-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* info page */

  .podcast-links {
    display: none;
  }
  .item-links {
    display: block;
  }

  .item-links .link {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    position: relative;
    font-family: "Roboto", -apple-system, "Open Sans", system-ui, "sans-serif";
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    white-space: normal;
    background: var(--body-background);
    color: var(--text-color);
    -webkit-transition: box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 24px 64px 24px 80px;
    text-decoration: none;
    border-bottom: 1px solid var(--podcast-link-separator-color);
  }

  .item-links .link:last-child {
    border-bottom: none;
  }

  @media (pointer: fine) {
    .item-links .link:active,
    .item-links .link:hover,
    .item-links .link:focus {
      background: rgba(68, 157, 229, 0.1);
    }
  }
  .item-links .link .img {
    position: absolute;
    top: 50%;
    left: 0;
    width: 48px;
    height: 48px;
    margin: -24px 16px;
  }
  .item-links .link .img img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    max-width: 100%;
    max-height: 100%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }
  .item-links .link .arr {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    margin: 0 21px;
    line-height: 0;
  }
  .item-links .link .arr svg {
    height: 21px;
    width: auto;
    fill: rgba(30, 139, 228, 0.3);
  }

  /* episode page */
  .episode-wrapper .episode-container:not(:last-child) {
    border-bottom: 1px solid var(--separator-color);
  }

  .episode-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  .episode-header-content .podcast-img-wrapper {
    position: absolute;
    top: -32px;
  }

  .episode-header-content .podcast-img {
    /*position: absolute;*/
    /*top: -32px;*/
  }
  .podcast-logo {
    position: relative;
    margin-bottom: 24px;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .episode-header .podcast-logo {
    margin-left: auto !important;
    margin-bottom: 56px;
  }
  .podcast-info-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .episode-header-content {
    position: relative;
    padding: 20px;
  }
  .podcast-info-wrapper .episode-header-description-wrapper .episode-podcast-link {
    text-align: center;
    padding-right: 0;
  }

  .podcast-info-wrapper .episode-header-description-wrapper h1 {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    /*padding-top: 16px;*/
  }
  .podcast-info-wrapper .episode-header-description-wrapper {
    margin-top: 200px;
    margin-left: 0;
  }
  .episode-description {
    /*margin-top: 160px;*/
    margin-left: 0;
    /*align-items: center;*/
  }
  .episode-header .container .img-btn {
    top: 110px;
    right: 85%;
    position: absolute;
    z-index: 1;
    transform: rotate(90deg);
  }
  .episode-page {
    max-width: 100%;
    padding: 0;
    padding-bottom: 24px;
  }
  .episode-page p {
    padding-right: 20px;
    padding-left: 20px;
  }
  .episode-page .services-list {
    padding-left: 40px;
    padding-right: 40px;
  }
  /* about page */

  .resources {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 0;
  }

  .resources .resource {
    padding: 20px;
  }

  .episode-page .resources .link-content {
    padding: 20px 0 !important;
  }

  .about {
    margin: 0;
    padding: 24px 20px 0;
  }

  .resources .resource:not(:last-child) {
    border-bottom: 1px solid var(--podcast-link-separator-color);
  }
  .resources .resource:first-child {
    border-top: 1px solid var(--podcast-link-separator-color);
  }

  .episode-page .spacer {
    display: none;
  }
}
