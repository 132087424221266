.progressContainer {
    order: 2;
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 14px;
    transition: margin 1000ms ease-in-out;
}

.ready {
    margin-top: 10px;
    margin-bottom: 10px;
}

.progressContainer .timeline {
    position: relative;
    border-radius: 2px;
    height: 2px;
    margin-top: 5px;
    background: var(--player-timeline-color);
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 2px;
  width: 100%;
}

.loading {
  background: var(--player-loading-color) url(loading.png) repeat-x 0 0;
  background-size: 7px 100%;
  -webkit-animation: loading linear 0.25s infinite;
  animation: loading linear 0.25s infinite;
}

@-webkit-keyframes loading {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 7px 0;
  }
}
@keyframes loading {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 7px 0;
  }
}

@media only screen and (max-width: 664px) {
    .ready {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
